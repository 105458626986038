<template>
  <PSection my-info-23s1>
    <div class="contents-holder">
      <SectionTitle title="MY INFO" title-ko="내 정보" black />
      <!-- article class="detail-info"></article -->
      <!-- <UserGameProfile/> -->
      <ToggleTab :tabs="['내 대회', '내 팀']" v-model="selectedIndex" v-prx:intro="{r:[3,5], ty:[100,0], o:[0,1]}" />
      <MyArena v-show="selectedIndex === 0" class="card-holder" :arenas="myArenas" v-prx:intro="{r:[3,5], ty:[100,0], o:[0,1]}" />
      <MyTeam v-show="selectedIndex === 1" :teams="myTeams" v-prx:intro="{r:[3,5], ty:[100,0], o:[0,1]}" />
    </div>
  </PSection>
</template>

<script>
import { getter, state } from '@shared/utils/storeUtils';
import ToggleTab from '@/views/components/common/tab/ToggleTab.vue';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import MyArena from '@/views/components/common/gameHome/MyArena.vue';
import MyTeam from '@/views/components/common/gameHome/MyTeam.vue';

export default {
  name: 'MyInfo',
  components: { ToggleTab, MyArena, MyTeam, SectionTitle },
  data() {
    return {
      selectedIndex: 0,
      myArenas: [],
      selectedTeamId: 0,
      myTeams: [],
    };
  },
  watch: {
    userId: 'fetchData',
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
    userId: state('user', 'userInfo.id'),
  },
  methods: {
    fetchData() {
      if (!this.isLogin) return false;

      this.getMyArena();
      this.getMyTeams();
    },
    async getMyArena() {
      const params = {
        q: 'isJoined eq true',
        size: 9,
        cursor: 0,
        order: 'joined desc',
      };

      const r = await this.$services.home.getArena(params);
      this.myArenas = r.items;
    },
    async getMyTeams() {
      const params = { q: 'isJoined eq true', size: 2, cursor: 0, order: 'joined desc' };
      const r = await this.$services.home.getMyTeams(params);

      if (r.items.length) {
        const teamIds = r.items.map(team => team.teamId);

        teamIds.forEach(async (tid, idx) => {
          const histories = await this.getMyTeamHistory(tid);
          this.myTeams.push({ ...r.items[idx], histories });
        });
      }
    },
    async getMyTeamHistory(teamId) {
      const res = await this.$services.home.getMyTeamHistory({ teamId, cursor: 0, size: 20 });
      return res.content || [];
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-info-23s1] { .bgc(white); .rel; z-index: 1;
  .contents-holder { .p(60, 20);
    > article { .mt(27);}
    .tab {.mt(24); .ib-list;
      button {.wh(120, 38); .br-t(8); .fs(14, 32px); .c(rgba(0, 0, 0, .4)); .bgc(#ebebf0); .rel;
        &.on {.bgc(black); .c(white);}
        &:nth-of-type(1) {.mr(4);}

        @media (max-width: 360px) {
          .w(auto); .ph(30);
        }
      }
      &:after {.cnt; .w(300); .h(1); .bgc(#ebebf0); .abs; .lb(0, 0);}
    }
    .card-holder { .mt(20);
      [my-team] .btn-more { .t(-54); }
      &[my-arena] {}
      [info-game-card] {.mt(12);
        &:nth-of-type(1) {.mt(0);}
      }
    }
  }
  @media (@tl-up) {
    .contents-holder { .p(60, 58, 99);
      .tab { .mt(48);
        button {.wh(200, 48); .fs(16, 48px);}
        &:after { .w(560); }
      }
      .card-holder { .mt(40);
        &[my-arena] {}
        [info-game-card] {.mt(16);}
      }
    }
  }
  @media (@ds-up) {
    .contents-holder { .w(@screen-ds-min); .mh-c; .p(120, 0);
      .tab {
        &:after { .w(100%); }
      }
    }
  }
}
</style>
