<template>
  <Modal schedule-modal-23s1 @close="close" theme="dark">
    <div class="img-holder">
      <img class="img-m" src="/img/pages/pubg/main/PUBG_logo_M.png" />
      <img class="img-pc" src="/img/pages/pubg/main/PUBG_logo_W.png" />
    </div>
    <img class="line" src="/img/pages/pubg/main/Divide_M.svg" />
    <div class="contents-top">
      <h3>2월<br /> PUBG 대회 일정</h3>
      <button @click="pubgSportsLeague">PUBG 이스포츠 프로 리그 보기</button>
    </div>
    <div class="contents-bottom">
      <img class="img-m" src="/img/pages/pls202301/calender_202301_m.png" />
      <img class="img-pc" src="/img/pages/pls202301/calender_202301_pc.png" />
    </div>
    <img class="line" src="/img/pages/pubg/main/Divide_M.svg" />
  </Modal>
</template>

<script>
import Modal from '@shared/components/common/Modal.vue';

export default {
  name: 'ScheduleModal',
  components: {
    Modal,
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    resolve() {
      this.$emit('resolve', false);
    },
    pubgSportsLeague() {
      window.open('https://www.pubgesports.com/kr');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[modal][schedule-modal-23s1] { .tc;
  > .panel {
    &.large { .w(355); .bgc(#24262c);
      > .header { .w(100%); .pt(31);}
      > .scroll-area { .p(0, 13, 48, 13); .w(100%); .mh-c;
        .img-holder { .w(100%);
          .img-m { .wh(160, 56); .mh-c; .block; }
          .img-pc { .wh(212, 74); .mh-c; .block; .hide;}
        }
        .line { .w(100%); .mh-c; .cover; .mb(22); .mt(21);
          &:nth-of-type(2) { .mb(0); .mt(0);}
        }

        .contents-top { .flex; justify-content: space-between; .mb(20);
          > h3 { .c(white); .w(100%); .fs(20, 24); .ls(-2); .max-w(137); .ib; .tl; .vab;}
          > button { .h(36); .bgc(#5b5c60); .ph(6); .br(8); .c(white); .ml(16); .font-spoqa; .medium; .fs(11, 36); .mt(4); }
        }

        .contents-bottom { .mb(28);
          .img-m { .wh(328, 318); }
          .img-pc { .hide; .wh(740, 504);}
        }
      }
    }
  }

  @media (@tl-up) {
    > .panel {
      &.large { .w(820);
        > .header { .pt(52);}
        > .scroll-area { .p(0, 40, 52, 40);
          .img-holder {
            .img-m { .hide;}
            .img-pc { .block;}
          }
          .line {
            &:nth-of-type(1) { .mt(28); .mb(40);}
          }
          .contents-top { .mb(24);
            > h3 { .max-w(258); .fs(28, 28); .mt(7);
              > br { .hide;}
            }
            > button { .h(40); .ph(9); .fs(12, 40); .mt(0);}
          }
          .contents-bottom { .mb(40);
            .img-m { .hide;}
            .img-pc { .block;}
          }
        }
      }
    }
  }
}

</style>
